import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import styles from './AbandonedCartToast.module.scss';

const AbandonedCartToast = ({
  id,
  header,
  description,
  onCloseCallback,
  onClickCTA,
  isCartBanner,
  cta,
  dontApplyPromoCode,
}) => (
  <div
    className={classNames({
      'abandoned-cart-toast-banner': isCartBanner,
      'abandoned-cart-toast-box': !isCartBanner,
      [styles.wrapper]: !isCartBanner,
      [styles['cart-wrapper']]: isCartBanner,
    })}
  >
    {!isCartBanner && <div className={styles['toast-icon']} />}
    <div className={styles['toast-container']}>
      <div className={styles['toast-header']}>
        {isCartBanner && <div className={styles['toast-icon']} />}
        <p
          className={styles['toast-title']}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitize(header) }}
        />
      </div>
      <p
        className={styles['toast-description']}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sanitize(description) }}
      />
      {cta?.title && (
        <button
          type="button"
          onClick={() => {
            if (dontApplyPromoCode) {
              window.location.href = cta.uri;
            } else {
              onClickCTA({ toastId: id });
            }
          }}
          className={styles['toast-link']}
        >
          {cta.title}
        </button>
      )}
      {isCartBanner && (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          type="button"
          className={styles['toast-close-btn']}
          onClick={onCloseCallback}
        />
      )}
    </div>
    {!isCartBanner && (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        type="button"
        className={styles['toast-close-btn']}
        onClick={onCloseCallback}
      />
    )}
  </div>
);

AbandonedCartToast.propTypes = {
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onCloseCallback: PropTypes.func.isRequired,
  onClickCTA: PropTypes.func.isRequired,
  isCartBanner: PropTypes.bool.isRequired,
  cta: PropTypes.shape({
    uri: PropTypes.string,
    title: PropTypes.string,
  }),
  dontApplyPromoCode: PropTypes.bool,
};

AbandonedCartToast.defaultProps = {
  cta: {
    uri: null,
    title: null,
  },
  dontApplyPromoCode: false,
};

export default AbandonedCartToast;
