import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import store from '../../store';
import messages from '../../i18n';
import AbandonedCartToasts from '../../blocks/AbandonedCartToasts';

const locale = 'en-US';

if (window.location.href.includes('/cart')) {
  render(
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale="en"
    >
      <Provider store={store}>
        <AbandonedCartToasts />
      </Provider>
    </IntlProvider>,
    document.getElementById('abandoned-cart-banner'),
  );
} else {
  render(
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale="en"
    >
      <Provider store={store}>
        <AbandonedCartToasts />
      </Provider>
    </IntlProvider>,
    document.getElementById('abandoned-cart-toast'),
  );
}
