import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AbandonedCartToast from '../../components/AbandonedCartToast';
import {
  CART_URL,
  ABANDONED_CART_TOASTS_SS_KEY,
  ABANDONED_CART_BANNERS_SS_KEY,
  SECOND_PROMO_PARAM,
  MATTRESS_CATEGORY,
} from './constants';
import useQueryParams from '../../hooks/useQueryParams';
import {
  getAvailableDiscountCodeForAbandonedCarts,
  getAbandonedCartToasts,
  getActiveCart,
  addPromoCode,
  getAvailableDiscountCodeForAbandonedCheckout,
  removePromoCode,
} from '../../slices/cart';

import {
  CONFLICTING_PROMOS_TOAST_TYPE_10OFF_CANNOT_BE_APPLIED,
  CONFLICTING_PROMOS_TOAST_TYPE_5OFF_CANNOT_BE_APPLIED,
  CONFLICTING_PROMOS_TOAST_TYPE_5OFF_REMOVED,
  CONFLICTING_PROMO_TOASTER_TYPE_TO_SHOW_SESSION_STORAGE_KEY,
  NEWDAY_TPM_PROMO,
} from '../../utils/common/constants';
import usePrevious from '../../hooks/usePrevious';
import { getCookie } from '../../utils/common';

const AbandonedCartToasts = () => {
  const abandonedCheckoutABTest = getCookie(
    process.env.ABANDONED_CHECKOUT_AB_TEST_COOKIE_NAME,
  )?.value;

  const isAbandonedCheckoutABTest = !!abandonedCheckoutABTest && abandonedCheckoutABTest !== 'expired';

  const dispatch = useDispatch();
  const isBanner = !window.location.href.includes(CART_URL);
  const dismissedSessionStorageKey = isBanner
    ? ABANDONED_CART_BANNERS_SS_KEY
    : ABANDONED_CART_TOASTS_SS_KEY;
  const {
    lineItems,
    abandonedCartToasts,
    createdAt: cartCreatedAt,
    discountCodeForAbandonedCarts,
    discountCodeForAbandonedCheckout
  } = useSelector((state) => state.cart);
  const [dismissedToasts, setDismissedToasts] = useState(
    sessionStorage.getItem(dismissedSessionStorageKey)?.split(',') ?? [],
  );

  const [promoCodeFromQueryParams] = useQueryParams(['code']);

  const hasMattressInCart = 
    !!lineItems.some((lineItem) => 
      lineItem?.category?.find((category) => category?.name === MATTRESS_CATEGORY));

  const allIntervalsZero = (toast) => !toast.attributes.field_interval_start
  && !toast.attributes.field_interval_end;

  const abandonedCartTriggeringToast = useMemo(() => {
    if (cartCreatedAt && abandonedCartToasts?.length) {
      const cartCreatedAtTimestamp = Date.parse(cartCreatedAt);
      const passedSeconds = (Date.now() - cartCreatedAtTimestamp) / 1000;

      return abandonedCartToasts.find(
        (toast) => !dismissedToasts.includes(toast.id) &&
          !allIntervalsZero(toast) &&
          (toast.attributes.field_interval_start?.seconds ?? 0) <=
            passedSeconds &&
          (!toast.attributes.field_interval_end?.seconds ||
            passedSeconds <= toast.attributes.field_interval_end.seconds)
      );
    }
    return null;
  }, [abandonedCartToasts, cartCreatedAt, dismissedToasts]);

  const abandonedCheckoutTriggeringToast = useMemo(() => {
    if (isAbandonedCheckoutABTest && abandonedCartToasts?.length) {
      return abandonedCartToasts.find(
        (toast) =>
          !dismissedToasts.includes(toast.id) &&
          !toast.attributes.field_interval_start
          && !toast.attributes.field_interval_end,
      );
    }
    return null;
  }, [abandonedCartToasts, dismissedToasts, isAbandonedCheckoutABTest]);


  const discountsAppliedWithoutFilter = useSelector((state) => {
    const discounts = state.cart.lineItems
      .filter((lineItem) => lineItem.custom?.fields?.discounts_json)
      .flatMap((lineItem) =>
        JSON.parse(lineItem.custom?.fields?.discounts_json).filter(
          (discount) => discount.method === 'coupon',
        ),
      )
      .map((discountJson) => discountJson.coupon_code);

    return [...new Set(discounts)];
  });

  const discountsApplied =
    discountsAppliedWithoutFilter?.filter(
      (discount) => discount !== NEWDAY_TPM_PROMO,
    ) ?? [];

  const isAbandonedCartPromoApplied = discountsApplied.find(
    (discount) => discount === discountCodeForAbandonedCarts?.code,
  );
  const isAbandonedCheckoutPromoApplied = discountsApplied.find(
    (discount) => discount === discountCodeForAbandonedCheckout?.code,
  );

  const triggeringToast = abandonedCartTriggeringToast || abandonedCheckoutTriggeringToast;

  const onDismissToast = (toastId) => {
    const currentDismissed =
      sessionStorage.getItem(dismissedSessionStorageKey)?.split(',') ?? [];
    const newDismissed = [...currentDismissed, toastId];
    sessionStorage.setItem(dismissedSessionStorageKey, newDismissed.join(','));
    setDismissedToasts(newDismissed);
  };

  const clickCtaHandler = async ({ toastId }) => {
    if (discountCodeForAbandonedCarts?.code &&
      toastId === abandonedCartTriggeringToast?.id) {
      if (isAbandonedCheckoutPromoApplied) {
        sessionStorage.setItem(
          CONFLICTING_PROMO_TOASTER_TYPE_TO_SHOW_SESSION_STORAGE_KEY,
          CONFLICTING_PROMOS_TOAST_TYPE_5OFF_REMOVED,
        );
        await dispatch(removePromoCode(isAbandonedCheckoutPromoApplied));
      }
      if (discountsAppliedWithoutFilter?.length && !isAbandonedCheckoutPromoApplied) {
        sessionStorage.setItem(
          CONFLICTING_PROMO_TOASTER_TYPE_TO_SHOW_SESSION_STORAGE_KEY,
          CONFLICTING_PROMOS_TOAST_TYPE_10OFF_CANNOT_BE_APPLIED,
        );
      }
      await dispatch(addPromoCode(discountCodeForAbandonedCarts.code));
    }
    if (discountCodeForAbandonedCheckout?.code &&
      toastId === abandonedCheckoutTriggeringToast?.id) {
      if (discountsAppliedWithoutFilter?.length) {
        sessionStorage.setItem(
          CONFLICTING_PROMO_TOASTER_TYPE_TO_SHOW_SESSION_STORAGE_KEY,
          CONFLICTING_PROMOS_TOAST_TYPE_5OFF_CANNOT_BE_APPLIED,
        );
      }
      await dispatch(addPromoCode(discountCodeForAbandonedCheckout.code));
    }
    onDismissToast(toastId);
    window.location.href = CART_URL;
  };

  useEffect(() => {
    if (!abandonedCartToasts) {
      dispatch(getAbandonedCartToasts());
    }
  }, [abandonedCartToasts, dispatch]);

  useEffect(() => {
    if (promoCodeFromQueryParams) {
      dispatch(addPromoCode(promoCodeFromQueryParams));
    }
  }, [dispatch, promoCodeFromQueryParams]);

  useEffect(() => {
    dispatch(getAvailableDiscountCodeForAbandonedCheckout(
      SECOND_PROMO_PARAM
    ));
    dispatch(getAvailableDiscountCodeForAbandonedCarts());
    dispatch(getActiveCart());
  }, [dispatch]);

  const triggeringToastFoundAndWeAreOnCartPage =
    window.location.pathname === CART_URL &&
    triggeringToast?.attributes?.field_promo_code;

  const triggeringToastFoundAndThereAreNoDiscountsApplied =
    triggeringToast && !isAbandonedCartPromoApplied;

  const triggeringToastFoundAndThereAreNoCheckoutDiscountsApplied =
    triggeringToast && !isAbandonedCartPromoApplied && !isAbandonedCheckoutPromoApplied;

  const cartIsEmpty = !lineItems?.length;

  const renderToast =
    (!cartIsEmpty &&
    !triggeringToastFoundAndWeAreOnCartPage &&
    triggeringToastFoundAndThereAreNoDiscountsApplied &&
    triggeringToast?.id !== abandonedCheckoutTriggeringToast?.id) ||
    (isAbandonedCheckoutABTest &&
      hasMattressInCart &&
      triggeringToastFoundAndThereAreNoCheckoutDiscountsApplied);

  const previousRenderToast = usePrevious(renderToast);

  useEffect(() => {
    if (!previousRenderToast && renderToast && triggeringToast?.id) {
      const detail = {
        promo: !triggeringToast?.attributes?.field_promo_code,
        messageName: triggeringToast?.attributes?.field_message_name,
        id: triggeringToast?.id,
      };
      const abandonedCartBannerEvent = new CustomEvent('abandonedCartBanner', {
        detail,
      });
      document.dispatchEvent(abandonedCartBannerEvent);
    }
  }, [
    previousRenderToast,
    renderToast,
    triggeringToast?.attributes?.field_message_name,
    triggeringToast?.attributes?.field_promo_code,
    triggeringToast?.id,
  ]);

  return renderToast ? (
    <AbandonedCartToast
      key={triggeringToast?.id}
      id={triggeringToast?.id}
      header={triggeringToast?.attributes.field_heading.value}
      description={triggeringToast?.attributes.field_description.processed}
      onCloseCallback={() => onDismissToast(triggeringToast?.id)}
      isCartBanner={!isBanner}
      cta={triggeringToast?.attributes.field_link}
      onClickCTA={({ toastId }) => clickCtaHandler({ toastId })}
      dontApplyPromoCode={triggeringToast?.attributes?.field_promo_code}
    />
  ) : null;
};

export default AbandonedCartToasts;
